import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { getRating } from '@/helpers/statusIncident.js';
import TicketComments from '@/components/request/elements/TicketComments/TicketComments.vue';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import RegisterAssetMaintenance from '@/components/inventory/elements/RegisterAssetMaintenance/index.vue';
import { showError } from '@/helpers/globalHelpers';
import { $EventBus } from '@/main';

export default {
	name: 'DetailReturn',
	data() {
		return {
			registerMaintenanceDialog: false,
			assetAvailable: false,
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Inventario',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssetsPermissions',
					},
				},
				{
					text: 'Lista de devoluciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'AssetReturnsList',
					},
				},
				{
					text: 'Detalle',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			statusList: [
				{ text: 'Solicitado', value: 'Solicitado' },
				{ text: 'Asignado', value: 'Asignado' },
				{ text: 'Resolución', value: 'Resolución' },
				{ text: 'Consulta', value: 'Consulta' },
				{ text: 'Cerrado', value: 'Cerrado' },
			],
			loading: false,
			filters: {
				q: null,
				page: 1,
				area: null,
				status: null,
				pagination: true,
				per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			},
			// mode: 'REQUEST', // TICKETSTARTED,REQUEST
			resolutionList: [
				{
					value: '1',
					text: 'Marcar como Disponible',
				},
				{
					value: '2',
					text: 'Enviar a Mantenimiento',
				},
				{
					value: '3',
					text: 'Dar de Baja',
				},
			],
			resolutionForm: {},
		};
	},

	created() {
		this.getRequestId();
	},

	computed: {
		...mapState('ticket', ['ticket']),
		currentAsset() {
			const assets = this.ticket?.assets;
			return Array.isArray(assets) && assets[0] ? assets[0] : null;
		},
	},

	methods: {
		getIncidentStatus,
		...mapActions('asset', ['enableAsset']),
		...mapActions('ticket', ['getTicketById', 'cleanTicket']),
		...mapActions('ticketAttention', ['createTicketAttention']),
		getRating,
		changeFilter() { },
		startTicket() {
			this.$swal({
				icon: 'question',
				title: 'Confirmación de Inicio',
				text: '¿Estás seguro de que deseas iniciar con este ticket?',
				showCancelButton: true,
				confirmButtonText: 'Sí, iniciar',
				cancelButtonText: 'Cancelar',
				confirmButtonColor: '#0039a6',
				cancelButtonColor: 'grey',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.assetAvailable = true;
				}
			});
		},

		async sendResolution(resolution) {
			let configSwal = null;
			if (resolution == 1) {
				configSwal = {
					title: 'Confirmación de cambio de disponiblidad de activo',
					text: '¿Estás seguro de cambiar a disponible este activo?',
					confirmButtonText: 'Sí, registrar',
				};
			} else if (resolution == 2) {
				this.registerMaintenanceDialog = true;
				// configSwal = {
				// 	title: 'Confirmación de Registro de mantenimiento',
				// 	text: '¿Estás seguro de que deseas registrar un mantenimiento?',
				// 	confirmButtonText: 'Sí, registrar',
				// };
			} else if (resolution == 3) {
				configSwal = {
					title: 'Confirmación de baja de activo',
					text: '¿Estás seguro de que deseas dar de baja este activo?',
					confirmButtonText: 'Sí, dar de baja',
				};
			}
			if (configSwal) {
				const result = await this.$swal({
					...configSwal,
					icon: 'question',
					showCancelButton: true,
					confirmButtonText: 'Confirmar',
					cancelButtonText: 'Cancelar',
					confirmButtonColor: '#0039a6',
					cancelButtonColor: 'grey',
				});
				if (result?.isConfirmed) {
					if (resolution == '1') {
					
						this.SendEnableAsset();
					} else if (resolution == '3') {
						
						this.$router.push({
							name: 'UnsubscribeAsset',
							params: { assetId: this.ticket.assets[0].asset_id },
						});
					}
				}
			}
		},

		async getRequestId() {
			try {
				this.loading = true;
				const id = this.$route.params.returnId;
				const { ok, error } = await this.getTicketById(id);
			
				if (!ok) {
					showError(error);
				}
			} catch (error) {
				showError(error);
			} finally {
				this.loading = false;
			}
		},

		async initiateTicket() {
			const payload = {
				company_id: parseInt(localStorage.getItem('company_id')),
				ticket: this.$route.params.returnId,
			};

			await this.createTicketAttention(payload);
			await this.getRequestId();
		},

		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},

		downloadFile(filePath) {
			const fileName = filePath.split('/').pop();
			let a = document.createElement('a');
			a.href = filePath;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		printSubtype(item) {
			return (
				item?.subtype?.classification?.area?.name +
				' / ' +
				item?.subtype?.classification?.name +
				' / ' +
				item?.subtype?.name
			);
		},
		printAssets(assets) {
			let assetsText = [];
			if (Array.isArray(assets)) {
				assets.forEach((asset) => {
					assetsText.push(asset?.name + ' ' + asset?.serial_number);
				});
			}
			return assetsText.join(', ');
		},

		getNamefile(url) {
			try {
				const urlParts = url.split('/');
				const fileNameWithTimestamp = urlParts[urlParts.length - 1];
				const fileNameParts = fileNameWithTimestamp.split('-');
				fileNameParts.shift();
				const fileName = fileNameParts.join('-');
				return fileName;
			} catch (error) {
				return 'EVIDENCIA';
			}
		},

		async SendEnableAsset() {
			const assets = this.ticket?.assets;
			if (Array.isArray(assets) && assets[0]) {
				this.loading = true;
				const { ok, error } = await this.enableAsset(assets[0].asset_id);
				if (ok) {
					this.getRequestId();
					$EventBus.$emit(
						'showSnack',
						'success',
						'¡Operación completada con éxito!'
					);
				} else {
					showError(error);
				}
				this.loading = false;
			}
		},
	},
	beforeDestroy() {
		this.cleanTicket();
	},
	components: {
		TicketComments,
		RegisterAssetMaintenance,
	},
};
