<template>
	<detail-return />
</template>
<script>
import DetailReturn from '@/components/inventory/assets/AssetReturns/DetailReturn/DetailReturn.vue';

export default {
	name: 'DetailReturnPage',
	components: { DetailReturn },
};
</script>
